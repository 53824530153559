<style lang="scss">
@import "leaflet/dist/leaflet.css";
$element-bg: #1a1a1a;

.container {
  position: absolute;
  font-family: sans-serif;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  background-color: black;
  display: flex;
  flex-direction: column;

  header {
    position: relative;
    height: 50px;
    background-color: $element-bg;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1020;

    > .title {
      padding: 10px;
      font-weight: bold;
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      > .version {
        font-size: 12px;
      }
    }


    .buttons > button {
      position: relative;
      height: 50px;
      color: white;
      border: none;
      border-radius: 4px;
      background-color: $element-bg;
      cursor: pointer;
      vertical-align: middle;
      transition: all 250ms;
      &.icon {
        width: 50px;
      }
      &:hover {
        svg {
          &.hamburger-icon {
            width: calc( 100% - 24px );
            height: calc( 100% - 24px );
          }

          &.cross-icon {
            width: calc( 80% - 24px );
            height: calc( 80% - 24px );
          }
        }
        background-color: rgba(255, 255, 255, 0.1);
      }

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        fill: #ffffff;
        transition: all 250ms;

        &.hamburger-icon {
          width: calc( 100% - 20px );
          height: calc( 100% - 20px );
        }

        &.cross-icon {
          width: calc( 80% - 20px );
          height: calc( 80% - 20px );
        }
      }


      &.hamburger {
        .hamburger-icon {
          opacity: 1;
        }

        .cross-icon {
          pointer-events: none;
          opacity: 0;
        }
      }
      &.cross {
        .hamburger-icon {
          opacity: 0;
          pointer-events: none;
        }

        .cross-icon {
          opacity: 1;
        }
      }
    }

    .profile-menu {
      position: absolute;
      right: 0px;
      top: calc( 100% + 10px );
      opacity: 0;
      pointer-events: none;
      transition: all 250ms;

      &.show {
        opacity: 1;
        pointer-events: auto;
        z-index: 1030;
      }
    }

  }

  .viewer {
    position: relative;
    width: 100%;
    height: calc( 100% - 50px );
    margin: 0;

    > div {
      background-color: black;
    }
  }

  .side {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 0px;
    top: 50px;
    height: calc( 100% - 50px );
    width: 300px;
    max-width: 100%;
    z-index: 1015;
    opacity: 0;
    background-color: $element-bg;
    transform: translateX(100%);
    transition: all 500ms;
    &.show {
      transform: translateX(0%);
      opacity: 1;
    }

    .title-wrap {
      position: relative;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-weight: bold;
        color: white;
        height: 50px;
        width: 100%;
      }

      .exit-button {
        position: absolute;
        float: left;
        width: 40px;
        padding: 10px;
        top: 6px;
        left: 10px;
        border: none;
        border-radius: 4px;
        background-color: transparent;
        cursor: pointer;
        transition: all 250ms;
        &:hover {
          background-color: rgba( 255, 255, 255, 0.1 );
          padding: 11px;
        }

        svg {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .zoom-control {
    position: absolute;
    left: 10px;
    top: 60px;
    display: flex;
    padding: 0;
    flex-direction: column;
    background-color: white;
    border-radius: 4px;
    align-items: center;
    overflow: auto;
    z-index: 1000;
    transition: all 250ms;

    *:first-child {
      border-bottom: #ccc solid 1px;
    }

    *:last-child {
      border-top: #ccc solid 1px;
    }

    .zoom-fit-content {
      position: relative;
      box-shadow: 0px 0px 0px 1px black;
      border-radius: 2px;
      width: 9px;
      height: 9px;
      display: inline-block;
    }

    > button {
      position: relative;
      width: 100%;
      background-color: white;
      border: none;
      padding: 4px 10px;
      cursor: pointer;
      font-size: 18px;
      transition: all 500ms;
    }

    > button:hover {
      background-color: #eee;
    }

    .zoom {
      padding: 10px 0px;
    }
  }

  .message-layer {
    position: absolute;
    z-index: 1010;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba( 0, 0, 0, 0.7 );
    opacity: 0;
    pointer-events: none;
    transition: all 250ms;

    .image {
      position: relative;
      width: 100px;
      height: 100px;
    }

    .message {
      position: relative;
      color: white;
      margin-top: 10px;
    }

    &.show {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

@media screen and (max-width: 600px) {
  .profile-button {
    display: none;
  }
}

@media screen and (max-width: 400px) {

  .container {

    .zoom-control {
      left: 50%;
      top: calc( 100% - 50px );
      transform: translateX(-50%);
      height: fit-content;
      flex-direction: row;

      .zoom {
        padding: 0px 20px;
      }

      *:first-child {
        border-bottom: none;
        border-right: #ccc solid 1px;
      }

      *:last-child {
        border-top: none;
        border-left: #ccc solid 1px;
      }
    }

    .side {
      bottom: 0px;
      top: 50px;
      height: calc(100% - 50px);
      width: 100%;
      z-index: 1020;
      background-color: $element-bg;
      transform: translateY(100%);
      transition: all 500ms;

      &.show {
        transform: translateY(0%);
      }
    }

  }
}
</style>

<script setup>
import {ref, computed, onMounted, onBeforeUnmount, watch} from 'vue'
import Jwt from '@/modules/Jwt';
import ProfileMenu from "@/components/ProfileMenu.vue";
import TileList from "@/components/TileList.vue";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer } from "@vue-leaflet/vue-leaflet";
import packageJson from '@/../package.json';

Jwt.get();
const dxfListShow = ref(true)
const center = ref([0, 0])
const viewer = ref();
const map = ref();
const errorTileUrl = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC`;
const maxNativeZoom = 6;
const maxZoom = maxNativeZoom * 2;

const showList = () => {
  dxfListShow.value = !dxfListShow.value
}

const headerButtonClass = computed (() => {
  return dxfListShow.value ? 'hamburger' : 'cross'
})

const profileMenuShow = ref(false)
const profileMenu = ref(null)
const profileButton = ref(null)

const showProfileMenu = () => {
  profileMenuShow.value = !profileMenuShow.value
}

const clickOther = (event) => {
  if(profileMenu.value.$el.contains(event.target) || (profileButton.value.contains(event.target) && profileMenuShow.value))
    profileMenuShow.value = true;
  else
    profileMenuShow.value = false;

}

const empData = ref({empName: '-', empOfficeName: '-', deptName: '-'})

async function loginData() {
  try {
    const employee = (await import(`@/api/employee${process.env.VUE_APP_DYNAMIC_SCRIPT_EXTENSION}`)).default;
    const data = await employee.get()
    if(data.result !== "SUCCESS" || data.data === null) {
      Jwt.delete();
    }
    empData.value.empName = data.data.empName === null ? '-' : data.data.empName
    empData.value.empOfficeName = data.data.empOfficeName === null ? '프로' : data.data.empOfficeName
    empData.value.deptName = data.data.deptName === null ? '-' : data.data.deptName
  } catch(error) {
    console.log(error)
  }
}

loginData()

const tileList = ref([])
const selectedTileId = ref(null)
const noFile = ref(false)

async function getTileList() {
  try {
    const tile = (await import(`@/api/tile${process.env.VUE_APP_DYNAMIC_SCRIPT_EXTENSION}`)).default;
    const data = await tile.getList()
    if(data.result !== "SUCCESS" || data.data.length === 0) {
      noFile.value = true
      return
    }
    tileList.value = data.data
    showTileImage(data.data[0])
  } catch(error) {
    console.log(error)
  }
}
getTileList()

const showTileImage = (tile) => {
  changeZoom([0, 0])
  selectedTileId.value = tile.id
  dxfUrl.value = `${process.env.VUE_APP_API_END_POINT}/public/cad/tile/${tile.id}/{z}/{x}/{y}${process.env.VUE_APP_TILE_API_EXTENSION}`;
}

const tileSize = 256
const leafletZoom = ref(0)
const zoom = ref(0);
const dxfUrl = ref("")

watch(() => leafletZoom.value, () => {

  zoom.value = leafletZoom.value;

});


const onClickZoomLevelDebounce = ( increaseZoom ) => {

  let _timeoutId = null;

  return () => {

    zoom.value += increaseZoom;
    if( zoom.value < 0 ){
      zoom.value = 0;
    }

    if( maxZoom < zoom.value ){
      zoom.value = maxZoom;
    }

    _timeoutId = setTimeout(() => {
      changeZoom(center.value, zoom.value);
    });

  };

};

const onClickIncreaseZoomLevel = onClickZoomLevelDebounce(1);
const onClickDecreaseZoomLevel = onClickZoomLevelDebounce(-1);
const onClickZoomFit = () => {

  changeZoom([0, 0])

}

const changeZoom = (center, zoomLevel = getFitZoom() ) => {

  if(zoomLevel < 0 || maxZoom < zoomLevel) {
    return;
  }

  if(!map.value.leafletObject){
    return;
  }

  map.value.leafletObject.flyTo(center, zoomLevel, {
    animate: true,
    duration: 0.2
  })

}

const getFitZoom = () => {

  const clientRect = viewer.value.getBoundingClientRect();
  const size = Math.min( clientRect.width, clientRect.height );
  return Math.floor( size / tileSize ) - 1;

};

onMounted(() => {
  document.addEventListener("click", clickOther)
});

onBeforeUnmount(() => {
  document.removeEventListener("click", clickOther)
});
</script>
<script>

</script>
<template>
  <div class="container">
    <!-- 헤더 -->
    <header>
      <div class="title">
        SGK CAD View <span class="version">v{{packageJson.version}}</span>
      </div>
      <div class="buttons">
        <button ref="profileButton" class="profile-button" @click="showProfileMenu">
          <span v-html="empData.empName + '(' + empData.empOfficeName + '/' + empData.deptName + ')'"></span>
          <ProfileMenu ref="profileMenu" class="profile-menu" :class="{show: profileMenuShow}" :user-text="empData.empName + '(' + empData.empOfficeName + '/' + empData.deptName + ')'"></ProfileMenu>
        </button>
        <button class="icon" :class="headerButtonClass" @click="showList">
          <svg class="hamburger-icon" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
              <path d="M172 4591 c-211 -73 -230 -365 -30 -465 l52 -26 2366 0 2366 0 52 26 c162 81 189 294 53 417 -80 72 116 67 -2475 67 -2235 -1 -2333 -1 -2384 -19z"/>
              <path d="M187 2806 c-60 -16 -113 -59 -152 -121 -26 -43 -30 -57 -30 -124 0 -65 4 -83 27 -122 31 -53 76 -94 128 -118 32 -15 254 -16 2400 -16 2146 0 2368 1 2400 16 52 24 97 65 128 118 23 39 27 57 27 122 0 67 -4 81 -30 124 -40 64 -101 110 -165 124 -80 17 -4669 14 -4733 -3z"/>
              <path d="M142 994 c-162 -81 -189 -294 -53 -417 80 -72 -115 -67 2471 -67 2586 0 2391 -5 2471 67 137 124 107 343 -55 419 l-51 24 -2366 0 -2365 0 -52 -26z"/>
            </g>
          </svg>
          <svg class="cross-icon" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
              <path d="M222 5102 c-141 -51 -231 -190 -220 -337 11 -127 -42 -70 1071 -1182 l1022 -1023 -1026 -1027 c-1149 -1150 -1064 -1054 -1064 -1208 0 -60 6 -92 22 -127 30 -66 96 -133 162 -165 47 -24 69 -28 136 -28 154 0 58 -84 1208 1064 l1027 1026 1028 -1026 c1150 -1149 1054 -1065 1207 -1063 99 0 174 33 234 100 63 72 84 126 85 219 2 153 86 57 -1063 1207 l-1026 1028 1026 1028 c1148 1149 1064 1053 1064 1207 0 67 -4 89 -28 136 -32 66 -99 132 -165 162 -35 16 -67 22 -127 22 -154 0 -58 85 -1208 -1064 l-1027 -1026 -1023 1022 c-858 859 -1030 1026 -1072 1044 -70 31 -174 35 -243 11z"/>
            </g>
          </svg>
        </button>
      </div>
    </header>
    <!-- 뷰어 -->
    <div ref="viewer" class="viewer">
      <l-map ref="map" :noBlockingAnimations="true" v-model:zoom="leafletZoom" :fadeAnimation="true" :zoomAnimation="true" v-model:center="center" :minZoom="0" :max-zoom="maxZoom" :options="{ zoomControl: false, attributionControl: false }">
        <l-tile-layer
          :url= "dxfUrl"
          layer-type="base"
          name="SgkDemoLeaflet"
          :noWrap=true
          :options="{
            maxNativeZoom,
            errorTileUrl
          }"
        ></l-tile-layer>
      </l-map>
    </div>

    <!-- 오른쪽 패널 -->
    <aside class="side" :class="{show: !dxfListShow}">
      <div class="title-wrap">
        <button class="exit-button" @click="showList">
          <svg data-v-200e64a0="" class="back-button" xmlns="http://www.w3.org/2000/svg" width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000" preserveAspectRatio="xMidYMid meet">
            <g data-v-200e64a0="" transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" fill="rgb(255,255,255)" stroke="none">
              <path data-v-200e64a0="" d="M59 561 c-24 -19 -24 -20 -24 -241 0 -278 -12 -260 179 -260 126 0 134 1 154 23 16 17 22 36 22 70 0 76 -35 69 -42 -8 l-3 -40 -124 -3 c-82 -2 -128 1 -137 9 -20 17 -20 401 0 418 9 8 55 11 137 9 l124 -3 3 -40 c7 -78 42 -84 42 -6 0 37 -5 52 -23 69 -21 20 -34 22 -154 22 -114 0 -134 -2 -154 -19z"></path><path data-v-200e64a0="" d="M463 434 c-4 -10 8 -30 32 -54 l39 -40 -195 -2 c-165 -3 -194 -5 -194 -18 0 -13 29 -15 194 -18 l195 -2 -39 -40 c-24 -24 -36 -44 -32 -54 4 -9 10 -16 14 -16 5 0 38 29 73 65 l64 65 -64 65 c-35 36 -68 65 -73 65 -4 0 -10 -7 -14 -16z"></path>
            </g>
          </svg>
        </button>
        <div class="title">tiles</div>
      </div>
      <TileList :tile-list="tileList" :selected-tile-id="selectedTileId" @selectTile="showTileImage"></TileList>
    </aside>

    <!-- 줌 컨트롤러 -->
    <div class="zoom-control">
      <button @click="onClickIncreaseZoomLevel" :disabled="zoom === maxZoom">+</button>
      <span class="zoom">{{ Math.round(zoom + 1) }}</span>
      <button @click="onClickZoomFit">
        <span class="zoom-fit-content"></span>
      </button>
      <button @click="onClickDecreaseZoomLevel" :disabled="zoom === 0">-</button>
    </div>

    <!-- 메세지 레이어 -->
    <div class="message-layer" :class="{show: noFile}">
      <img class="image" src="@/assets/images/warning.png" alt="warning!">
      <div class="message">불러올 타일 도면이 없습니다.</div>
    </div>
  </div>
</template>