<style lang="scss" scoped>
.profile-menu-wrap {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 2px 1px #cccccc;


  .profile-menu-item {
    display: flex;
    padding: 10px;
    height: 20px;
    border-bottom: #ccc solid 1px;
    cursor: default;
    color: #616161;

    &:not(.no-hover):hover {
      cursor: pointer;
      text-decoration: underline;
    }

    &:last-child {
      border-bottom: none;
    }

    .profile-item-icon {
      height: 20px;
    }
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0px;
    cursor: inherit;
    font-weight: bold;
  }

  svg {
    height: 100%;
  }
}
</style>

<script setup>
import Jwt from "@/modules/Jwt";

const props = defineProps({
  userText: {
    Type: String,
    default: ""
  }
});

const methods = {
  onClickLogoutButton: () => {

    Jwt.delete();

  }
};
</script>

<template>
  <div class="profile-menu-wrap">
    <div class="profile-menu-item no-hover">
        {{ props.userText }}
    </div>
    <div class="profile-menu-item" @click="methods.onClickLogoutButton">
        <button>logout</button>
    </div>
  </div>
</template>

